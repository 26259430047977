//
//
//
//
//
//

import Vue from 'vue'
export default {
  name: 'radiantComponent',
  props: [
    'url',
    'title',
    'type',
    'type_content',
    'drm',
    'data_player',
    'videoPlaying',
    'thumbnails',
    'content_player_type',
    'vast',
    'getPlayerInstance',
    'setCurrentState',
    'loadEpgAfterClick',
    'setAdsStatus',
    'showUiController',
    'contentDetails',
    'dataIslive',
    'title_catchup'
  ],
  data() {
    return {
      settings: {
      licenseKey: 'dHhuZWFrenZ6dkAxNjIwMzc5',
      src: '',
      width: 640,
      height: 480,
      automaticFullscreenOnLandscape: false,
      autoHeightMode: true,
      googleCast: false,
      pip: true,
      posterScaleMode: 'zoom',
      shakaOffline: false,
      hideModule: {
        quality: true,
        captions: true,
        audio: true,
        volume: false
      },
      manualSwitchingMode: 'instant',
      ccFiles: null,
      offsetStartPosition: 0,
      ccFontSize: 1.75,
      ccBackgroundOpacity: 0,
      ads: true,
      adTagUrl: '',
      adEnablePreloading: false,
      adVpaidMode: 'enabled',
      adVpaidControls: true,
      }
    };
  },
  mounted() {
    this.settings = {
      ...this.settings,
      logo: process.env.WATERMARK_LOGO_URL,
      logoPosition: ((this.$route.name == 'Embed Live') ? 'bottomleft' : 'bottomright'),
      logoWatermark: true,
      logoMargin: '16px',
      initialVolume: 0.3,
      autoplay: this.$route.query.second ? false : true,
      forceInitialVolume: true,
      contentMetadata: {
        poster: [
          this.thumbnails
        ]
      },
    }
    this.initVod()
  },
  watch: {
    url: function(val) {
      let vm = this
      vm.initVod()
    }
  },
  methods: {
    initVod() {
      if (this.url) {
        if (this.drm) {
          this.drmAndroid()
        } else {
          this.nonDrm()
        }
      }
    },
    nonDrm () {
      var src = {
        hls: this.url
      };
      this.settings.src = src
      this.settings.ads = true
      this.settings.adTagUrl = this.vast

      var elementID = 'rmpPlayer';
      var rmp = new RadiantMP(elementID);
      rmp.init(this.settings);
      this.$emit('setRmp', rmp);
      var rmpContainer = document.getElementById(elementID);
      this.eventListenerPlayer(rmpContainer,rmp)
    },
    drmAndroid () {
      let vm = this
      let k = this.data_player.k
      let uid = process.env.CLIENT_ID;
      let key = this.get_key(uid.toString()),
        iv = this.get_iv(k),
        en = this.get_en(k),
        keys = this.hexStr2ArrByte(key),
        ivs = this.hexStr2ArrByte(iv),
        ens = this.hexStr2ArrByte(en);

      this.importSecretKey(keys)
      .then(function (data) {
        vm.decryptMessage(data, ivs, ens)
          .then(function (text) {
            var dec = new TextDecoder().decode(text);
            var src = {
              dash: vm.url
            };
            vm.settings.src = src
            vm.settings.shakaDrm = {}
            vm.settings.shakaDrm.servers = {}
            vm.settings.shakaDrm.servers[process.env.DRMTYPENONSAFARI] = dec
            vm.settings.shakaDrm.advanced = {}
            vm.settings.shakaDrm.advanced[process.env.DRMTYPENONSAFARI] = {
              'videoRobustness': 'SW_SECURE_CRYPTO',
              'audioRobustness': 'SW_SECURE_CRYPTO'
            }
            vm.settings.ads = true
            vm.settings.adTagUrl = vm.vast

            var elementID = 'rmpPlayer';
            var rmp = new RadiantMP(elementID);
            var rmpContainer = document.getElementById(elementID);
            vm.eventListenerPlayer(rmpContainer,rmp)
            rmp.init(vm.settings);

          })
          .catch(function (error) {
            console.log('decryptMessage', error);
          });
      })
      .catch(error => {
        console.log('importSecretKey', error);
      })
    },
    eventListenerPlayer(rmpContainer,rmp) {
      let vm = this
      Vue.prototype.globalConstants = {
        instance: rmp
      }

      rmpContainer.addEventListener('destroycompleted', function() {
        console.log("======= destroycompleted ========");
      });

      rmpContainer.addEventListener('error', function() {
        console.log('fatal error detected');
        console.log(rmp.getErrorData());
      });

      rmpContainer.addEventListener('warning', function() {
        console.log('non-fatal error detected');
        console.log(rmp.getErrorData());
      });

      rmpContainer.addEventListener('loadeddata', function() {
        vm.callConviva(rmpContainer,rmp)
        vm.$emit('loadEpgAfterClick', false);
      });

      rmpContainer.addEventListener('playing', function() {
        var listBit = rmp.getBitrates()
        rmp.setBitrate(listBit.length-1)
      });

      rmpContainer.addEventListener('levelswitching', function() {
        var listBit = rmp.getBitrates()
        var idx = rmp.getCurrentBitrateIndex()
        if (listBit[idx]) {
          console.log("quality", listBit[idx].height);
        }
      });

      rmpContainer.addEventListener('playing', function() {
        console.log("info quality", rmp.getBitrates() , rmp.getCurrentBitrateIndex());
      });

      rmpContainer.addEventListener('adloaded', function () {
          console.log('adloaded');
        });
        rmpContainer.addEventListener('adstarted', function () {
          console.log('adstarted');
          console.log(rmp.getDuration());
          console.log(rmp.getAdTitle());
        });
        rmpContainer.addEventListener('adclick', function () {
          console.log('adclick');
        });
        rmpContainer.addEventListener('aderror', function () {
          console.log('aderror');
          console.log(rmp.getAdErrorType());
          console.log(rmp.getAdErrorCode());
          console.log(rmp.getAdErrorMessage());
          console.log(rmp.getAdVastErrorCode());
          vm.settings.ads = false
          vm.settings.adTagUrl = ''
        });
    },
    callConviva (rmpContainer,rmp) {
      let vm = this
      var CONTENT_TYPE = this.content_player_type.toLowerCase()
      let titleMovie = ''
      let isLive = false

      if (CONTENT_TYPE == 'catch_up') {
        CONTENT_TYPE  = 'CATCHUP'
        titleMovie = vm.contentDetails.title+"; "+ vm.title_catchup
      } else if (CONTENT_TYPE == 'live') {
        CONTENT_TYPE  = 'CHANNEL_LIVE_PROGRAM'
        titleMovie = vm.contentDetails.title+"; "+ (vm.dataIslive ? vm.dataIslive.t : '')
        isLive = true
      } else if (CONTENT_TYPE == 'catch_hybrid') {
        CONTENT_TYPE  = 'CATCHUP_HYBRID'
        titleMovie = vm.contentDetails.title
      } else if (CONTENT_TYPE == 'series') {
        CONTENT_TYPE  = 'VOD_SERIES'
        let contentCore_id = vm.data_player.contentCore_id
        let seasons = vm.contentDetails.seasons
        for (let index = 0; index < seasons.length; index++) {
            let getDataSeasons = seasons[index].episodes.find(o => o.id == contentCore_id)
            if (getDataSeasons) {
                let seasons_text = seasons[index].title
                let title_episode = getDataSeasons.title
                titleMovie = vm.contentDetails.title + " (" + seasons_text + " - " + title_episode + ")"
                break
            }
        }
      } else if (CONTENT_TYPE == 'movies') {
        CONTENT_TYPE  = 'VOD_MOVIE'
        titleMovie = vm.contentDetails.title
      }

      var CONTENT_DRM_STATUS = this.drm.toString();

      var CONTENT_DRM_TYPE = process.env.DRMTYPESAFARI;
      if (!CONTENT_DRM_TYPE) { CONTENT_DRM_TYPE    = 'NO_DRM';}

      var VPLUS_PARTNERS = 'MPI'

      // function loadModules() {
      var NexConviva = new NexHandshake(rmpContainer, vm.url, isLive, true, titleMovie, process.env.CLIENT_ID, process.env.CONVIVA_CUSTOMER_KEY,process.env.CONVIVA_GATEWAY_URL);

      vm.$emit('setHandshake', NexConviva);
      vm.nexConvi = NexConviva

      NexConviva.initConvivaClient();
      NexConviva.createContentSession();

      var listOfBitrates = rmp.getBitrates()
      var nowBitrate = listOfBitrates.length > 0 ? listOfBitrates[rmp.getCurrentBitrateIndex()] : null

      // Get initial bitrate
      NexConviva.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
      //console.log("Initial track", player.getCurrentTrack().bitrate / 1000); // "bitrate" property is in bits per seconds

      // Use this in order to update the bitrate data everytime a track changes
      rmpContainer.addEventListener('levelswitching', function() {
        // Invoke API methods
        NexConviva.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
        // loadModules()
      });

      // Example of creating a custom tag
      NexConviva.createCustomTag("CONTENT_TYPE", CONTENT_TYPE, true);
      NexConviva.createCustomTag("CONTENT_DRM_STATUS", CONTENT_DRM_STATUS, true);
      NexConviva.createCustomTag("CONTENT_DRM_TYPE", CONTENT_DRM_TYPE, true);
      NexConviva.createCustomTag("VPLUS_PARTNER", VPLUS_PARTNERS, true);
      NexConviva.updateContentMetadata();
      // }

      // =========end set for conviva==========

      // ========= conviva live channle =========
      if (CONTENT_TYPE  == 'CHANNEL_LIVE_PROGRAM' || CONTENT_TYPE  == 'CHANNEL_LIVE_VISITOR') {
        var NexConvivaLive = new NexHandshake(rmpContainer, vm.urls, isLive, true, vm.contentDetails.title, process.env.CLIENT_ID, process.env.CONVIVA_CUSTOMER_KEY,process.env.CONVIVA_GATEWAY_URL);

        vm.$emit('setHandshakeLiveProgram', NexConvivaLive);

        NexConvivaLive.initConvivaClient();
        NexConvivaLive.createContentSession();

        // Get initial bitrate
        NexConvivaLive.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
        //console.log("Initial track", player.getCurrentTrack().bitrate / 1000); // "bitrate" property is in bits per seconds

        // Use this in order to update the bitrate data everytime a track changes
        rmpContainer.addEventListener('levelswitching', function() {
          // Invoke API methods
          NexConvivaLive.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
          // loadModules()
        });

        // Example of creating a custom tag
        NexConvivaLive.createCustomTag("CONTENT_TYPE", 'CHANNEL_LIVE', true);
        NexConvivaLive.createCustomTag("CONTENT_DRM_STATUS", CONTENT_DRM_STATUS, true);
        NexConvivaLive.createCustomTag("CONTENT_DRM_TYPE", CONTENT_DRM_TYPE, true);
        NexConvivaLive.createCustomTag("VPLUS_PARTNER", VPLUS_PARTNERS, true);
        NexConvivaLive.updateContentMetadata();
      }
    // ========= end conviva live channle =========

    },
    utoa(str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    },
    chipher(messageToencrypt, secretkey) {
      var encryptedMessage = CryptoJS.AES.encrypt(messageToencrypt, secretkey);
      return encryptedMessage.toString();
    },
    ecrypt(message, key) {
      var code = CryptoJS.AES.decrypt(message, key);
      var decryptedMessage = code.toString(CryptoJS.enc.Utf8);

      return decryptedMessage;
    },
    reverseString(str) {
      var newString = "";
      for (var i = str.length - 1; i >= 0; i--) {
        newString += str[i];
      }
      return newString;
    },
    digit16(str) {
      while (str.length < 16) {
        str = "0" + str
      }
      return str;
    },
    encbs64(str) {
      return window.btoa(str)
    },
    decbs64(str) {
      return window.atob(str)
    },
    ascii_to_hexa(str) {
      var arr1 = [];
      for (var n = 0, l = str.length; n < l; n++) {
        var hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
      }
      return arr1.join('');
    },
    get_iv(str) {
      var iv = ""
      var i = 0;
      while (iv.length < 24) {
        iv = iv + str[i];
        i += 1;
      }
      return iv;
    },
    get_en(str) {
      var en = ""
      var i = 24;
      while (i < str.length) {
        en = en + str[i];
        i += 1;
      }
      return en;
    },
    get_utf8(str) {
      return str = encodeURI(str);
    },
    get_key(str) {
      str = this.reverseString(str);
      str = this.encbs64(str);
      str = this.digit16(str);
      str = this.ascii_to_hexa(str);
      str = this.get_utf8(str);
      return str;
    },
    importSecretKey(rawKey) {
      return window.crypto.subtle.importKey(
        "raw",
        rawKey,
        "AES-GCM",
        true,
        ["encrypt", "decrypt"]
      );
    },
    decryptMessage(_key, _iv, _en) {
      return window.crypto.subtle.decrypt({
          name: "AES-GCM",
          iv: _iv
        },
        _key,
        _en
      );
    },
    hexStr2ArrByte(_hexString) {
      var typedArray = new Uint8Array(_hexString.match(/[\da-f]{2}/gi).map(function (h) {
        return parseInt(h, 16);
      }));

      return typedArray;
    },
    base64EncodeUint8Array(input) {
      var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
      // var keyStr = "cdd9eab3335c9abd97e27d89852d8ee5";
      var output = "";
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
          keyStr.charAt(enc3) + keyStr.charAt(enc4);
      }
      return output;
    },
  }
}


//
//
//
//
//
//

import Vue from 'vue'
export default {
  name: 'radiantComponentIos',
  props: [
    'url',
    'title',
    'type',
    'type_content',
    'drm',
    'data_player',
    'videoPlaying',
    'thumbnails',
    'content_player_type',
    'vast',
    'getPlayerInstance',
    'setCurrentState',
    'loadEpgAfterClick',
    'setAdsStatus',
    'showUiController',
    'contentDetails',
    'dataIslive',
    'title_catchup'
  ],
  mounted() {
    let vm = this
    var src = {
      fps: this.url
    };
    var settings = {
      licenseKey: 'dHhuZWFrenZ6dkAxNjIwMzc5',
      src: src,
      width: 640,
      height: 480,
      autoplay: true,
      automaticFullscreenOnLandscape: false,
      autoHeightMode: true,
      googleCast: false,
      pip: true,
      shakaOffline: false,
      hideModule: {
        quality: false,
        captions: true,
        audio: true,
        volume: false
      },
      manualSwitchingMode: 'instant',
      ccFiles: null,
      offsetStartPosition: 0,
      ccFontSize: 1.75,
      ccBackgroundOpacity: 0,
      ads: true,
      adTagUrl: '',
      adEnablePreloading: false,
      adVpaidMode: 'enabled',
      adVpaidControls: true,
      // passing FPS DRM settings
      fpsDrm: {
          certificatePath: process.env.DRMKEYSAFARI,
          // processSpcPath = dec
          processSpcPath: 'https://fpsa.ptmnc01.verspective.net:8064/fpsa/v1.0/?deviceId=NjExZWU4OTctOTdiZS0zOTQ0LWE3Y2UtYTIxZWVmODBlNjEz',
          keySystem: process.env.DRMTYPESAFARI,
          extractContentId: function (initData) {
            var arrayToString = function (array) {
              var uint16array = new Uint16Array(array.buffer);
              return String.fromCharCode.apply(null, uint16array);
            };
            var rawContentId = arrayToString(initData);
            var tmp = rawContentId.split('/');
            let contentId = tmp[tmp.length - 1];
            return contentId;
          },
          licenseRequestHeaders: [
            {
              name: 'Content-type',
              value: 'application/x-www-form-urlencoded'
            }
          ],
          licenseRequestMessage: function (message, session) {
            return 'spc=' + encodeURIComponent(base64EncodeUint8Array(message)) + '&assetId=' + encodeURIComponent(session.contentId);
          },
          licenseRequestLoaded: function (event) {
            var request = event.target;
            var session = request.session;
            var keyText = request.responseText.trim();
            if (keyText.substr(0, 5) === '<ckc>' && keyText.substr(-6) === '</ckc>') {
              keyText = keyText.slice(5, -6);
            }
            var jsonkeyText = JSON.parse(keyText)
            var key = base64DecodeUint8Array(jsonkeyText.ckc);
            session.update(key);
          }
      }
    };

    function base64DecodeUint8Array(input) {
            var raw = window.atob(input)
            var rawLength = raw.length
            var array = new Uint8Array(new ArrayBuffer(rawLength))

            for(var i = 0; i < rawLength; i++)
                array[i] = raw.charCodeAt(i)

            return array
          }
          function base64EncodeUint8Array(input) {
            let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
            let output = ""
            let chr1, chr2, chr3, enc1, enc2, enc3, enc4
            let i = 0

            while (i < input.length) {
                chr1 = input[i++]
                chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
                chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

                enc1 = chr1 >> 2
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
                enc4 = chr3 & 63

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64
                } else if (isNaN(chr3)) {
                    enc4 = 64
                }
                output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
                    keyStr.charAt(enc3) + keyStr.charAt(enc4)
            }
            return output
          }

    settings.ads = true
    settings.adTagUrl = vm.vast

    // =========set for conviva==========

      var elementID = 'rmpPlayer';
      var rmp = new RadiantMP(elementID);
      var rmpContainer = document.getElementById(elementID);

      // =========listerner ads==========

        rmpContainer.addEventListener('adloaded', function () {
          console.log('adloaded');
        });
        rmpContainer.addEventListener('adstarted', function () {
          console.log('adstarted');
          console.log(rmp.getDuration());
          console.log(rmp.getAdTitle());
        });
        rmpContainer.addEventListener('adclick', function () {
          console.log('adclick');
        });
        rmpContainer.addEventListener('aderror', function () {
          console.log('aderror');
          console.log(rmp.getAdErrorType());
          console.log(rmp.getAdErrorCode());
          console.log(rmp.getAdErrorMessage());
          console.log(rmp.getAdVastErrorCode());
          settings.ads = false
          settings.adTagUrl = ''
        });

      // ========= end listerner ads==========

      rmp.init(settings);


      var CONTENT_TYPE = this.content_player_type.toLowerCase()
      let titleMovie = ''
      let isLive = false

      if (CONTENT_TYPE == 'catch_up') {
        CONTENT_TYPE  = 'CATCHUP'
        titleMovie = vm.contentDetails.title+"; "+ vm.title_catchup
      } else if (CONTENT_TYPE == 'live') {
        CONTENT_TYPE  = 'CHANNEL_LIVE_PROGRAM'
        titleMovie = vm.contentDetails.title+"; "+ (vm.dataIslive ? vm.dataIslive.t : '')
        isLive = true
      } else if (CONTENT_TYPE == 'catch_hybrid') {
        CONTENT_TYPE  = 'CATCHUP_HYBRID'
        titleMovie = vm.contentDetails.title
      } else if (CONTENT_TYPE == 'series') {
        CONTENT_TYPE  = 'VOD_SERIES'
        let contentCore_id = vm.data_player.contentCore_id
        let seasons = vm.contentDetails.seasons
        for (let index = 0; index < seasons.length; index++) {
            let getDataSeasons = seasons[index].episodes.find(o => o.id == contentCore_id)
            if (getDataSeasons) {
                let seasons_text = seasons[index].title
                let title_episode = getDataSeasons.title
                titleMovie = vm.contentDetails.title + " (" + seasons_text + " - " + title_episode + ")"
                break
            }
        }
      } else if (CONTENT_TYPE == 'movies') {
        CONTENT_TYPE  = 'VOD_MOVIE'
        titleMovie = vm.contentDetails.title
      }

      var CONTENT_DRM_STATUS = this.drm.toString();

      var CONTENT_DRM_TYPE = process.env.DRMTYPESAFARI;
      if (!CONTENT_DRM_TYPE) { CONTENT_DRM_TYPE    = 'NO_DRM';}

      var VPLUS_PARTNERS = 'MAXSTREAM'

      function loadModules() {
        var NexConviva = new NexHandshake(rmpContainer, src.fps.src, isLive, true, titleMovie, process.env.CLIENT_ID, process.env.CONVIVA_CUSTOMER_KEY,process.env.CONVIVA_GATEWAY_URL);

        vm.$emit('setHandshake', NexConviva);
        vm.nexConvi = NexConviva

        NexConviva.initConvivaClient();
        NexConviva.createContentSession();

        var listOfBitrates = rmp.getBitrates()
        var nowBitrate = listOfBitrates.length > 0 ? listOfBitrates[rmp.getCurrentBitrateIndex()] : null

        // Get initial bitrate
        NexConviva.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
        //console.log("Initial track", player.getCurrentTrack().bitrate / 1000); // "bitrate" property is in bits per seconds

        // Use this in order to update the bitrate data everytime a track changes
        rmpContainer.addEventListener('levelswitching', function() {
          // Invoke API methods
          NexConviva.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
          // loadModules()
        });

        // Example of creating a custom tag
        NexConviva.createCustomTag("CONTENT_TYPE", CONTENT_TYPE, true);
        NexConviva.createCustomTag("CONTENT_DRM_STATUS", CONTENT_DRM_STATUS, true);
        NexConviva.createCustomTag("CONTENT_DRM_TYPE", CONTENT_DRM_TYPE, true);
        NexConviva.createCustomTag("VPLUS_PARTNER", VPLUS_PARTNERS, true);
        NexConviva.updateContentMetadata();
      }

      // ========= conviva live channle =========
      if (CONTENT_TYPE  == 'CHANNEL_LIVE_PROGRAM' || CONTENT_TYPE  == 'CHANNEL_LIVE_VISITOR') {
        var NexConvivaLive = new NexHandshake(rmpContainer, vm.urls, isLive, true, vm.contentDetails.title, process.env.CLIENT_ID, process.env.CONVIVA_CUSTOMER_KEY,process.env.CONVIVA_GATEWAY_URL);

        vm.$emit('setHandshakeLiveProgram', NexConvivaLive);

        NexConvivaLive.initConvivaClient();
        NexConvivaLive.createContentSession();

        // Get initial bitrate
        NexConvivaLive.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
        //console.log("Initial track", player.getCurrentTrack().bitrate / 1000); // "bitrate" property is in bits per seconds

        // Use this in order to update the bitrate data everytime a track changes
        rmpContainer.addEventListener('levelswitching', function() {
          // Invoke API methods
          NexConvivaLive.updateBitrateData(nowBitrate ? nowBitrate.bandwidth / 1000 : 0);
          // loadModules()
        });

        // Example of creating a custom tag
        NexConvivaLive.createCustomTag("CONTENT_TYPE", 'CHANNEL_LIVE', true);
        NexConvivaLive.createCustomTag("CONTENT_DRM_STATUS", CONTENT_DRM_STATUS, true);
        NexConvivaLive.createCustomTag("CONTENT_DRM_TYPE", CONTENT_DRM_TYPE, true);
        NexConvivaLive.createCustomTag("VPLUS_PARTNER", VPLUS_PARTNERS, true);
        NexConvivaLive.updateContentMetadata();
      }
    // ========= end conviva live channle =========

      // =========end set for conviva==========

      // =========== event listener ===========

      Vue.prototype.globalConstants = {
        instance: rmp
      }

      rmpContainer.addEventListener('destroycompleted', function() {
        console.log("======= destroycompleted ========");
      });

      rmpContainer.addEventListener('error', function() {
        console.log('fatal error detected');
        console.log(rmp.getErrorData());
      });

      rmpContainer.addEventListener('warning', function() {
        console.log('non-fatal error detected');
        console.log(rmp.getErrorData());
      });

      rmpContainer.addEventListener('loadeddata', function() {
        loadModules()
      });

      rmpContainer.addEventListener('playing', function() {
        console.log('playing');
      });

      // =========== end event listener ===========
  },
  methods: {}
}

